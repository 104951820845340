import React, { useMemo, useContext, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/HistoryTwoTone';
import TierCard from './TierCard';
import LiveTransactionCard from './LiveTransactionCard';
import { useUserConfig } from 'hooks/useUserConfig';
import CommonContext from 'features/context/commonContext';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import feathers from 'services/feathers';
import { nanoid } from 'nanoid';

export default function Wallet(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hideLiveTransaction } = useUserConfig();
  const { companySetting } = useContext(CommonContext);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const showLiveTransaction = useMemo(
    () => {
      const hideLiveTransactionServer = companySetting?.walletConfig?.hideLiveTransaction || false;
      return (!hideLiveTransaction && !hideLiveTransactionServer);
    }, [hideLiveTransaction, companySetting]
  );

  const handleClicked = (route) => (event) => {
    event.preventDefault();
    navigate(`/wallet/${route}`);
  };

  const handleDepositClicked = useCallback(() => {
    async function depositPrecheck() {
      try {
        await feathers.service('deposit-precheck').get(nanoid());
        navigate(`/wallet/deposit`);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }
    depositPrecheck();
  }, [navigate, setGlobalErrorMessage]);

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TierCard />
        </Grid>
        <Grid item xs={12}>
          <Card elevation={4}>
            <CardHeader
              title={t('Transaction')}
              action={
                <Button color='info' variant='contained' onClick={handleClicked('history')}>
                  <HistoryIcon />
                </Button>
              }
            />
            <CardActions>
              <Button fullWidth variant='contained' onClick={handleDepositClicked} sx={{ fontSize: '1.1rem' }}>{t('Deposit')}</Button>
              <Button fullWidth variant='contained' onClick={handleClicked('withdrawal')} sx={{ fontSize: '1.1rem' }}>{t('Withdrawal')}</Button>
            </CardActions>
          </Card>
        </Grid>
        {
          !!showLiveTransaction && <Grid item xs={12}>
            <LiveTransactionCard />
          </Grid>
        }
      </Grid>
    </Box>
  );
}
