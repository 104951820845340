import { get, groupBy, orderBy } from 'lodash';
import { isBetweenHour } from './dt';

export function getFullBankLabel(bank) {
  const name = get(bank, 'name', '');
  const bankOptionCode = get(bank, 'bankOption.code', '');
  const bankOptionName = get(bank, 'bankOption.name', '');
  const bankOptionGroup = get(bank, 'bankOption.group', '');
  const isNotBankGroup = bankOptionGroup !== 'Bank';
  const codeIsEndsWithBank = bankOptionCode.endsWith('_bank');

  // The display number in this case is the bank name
  const additionalLabel = isNotBankGroup && codeIsEndsWithBank ? `${bank.displayNumber}` : '';

  const formattedBankName = additionalLabel ? `${name} (${additionalLabel})` : name;

  const bankName = [
    bankOptionName || bankOptionCode,
    formattedBankName,
  ].filter(Boolean).join(' - ');

  return bankName;
}

export function getCompanyBankOptions(t, banks) {
  const mappedBankList = banks.map(function (bank) {
    const bankOptionGroup = get(bank, 'bankOption.group', '');
    const bankName = getFullBankLabel(bank);

    const dailyMaintenance = get(bank, 'dailyMaintenance');
    const { startsAtTime, endsAtTime } = dailyMaintenance || {};
    const isDailyMaintenance = isBetweenHour(startsAtTime, endsAtTime);

    const label = isDailyMaintenance ? `🛠️ ${bankName}` : bankName;

    return {
      ...bank,
      type: bankOptionGroup,
      label,
      isDailyMaintenance,
    }
  });

  const sortedBankList = orderBy(mappedBankList, ['isDailyMaintenance', 'type', 'label'], ['asc', 'asc', 'asc']);

  const groupByType = groupBy(sortedBankList, 'type');

  let ret = [];
  Object.keys(groupByType).forEach((key, index) => {
    ret.push(
      <optgroup key={key} label={t(key)}>
        {
          groupByType[key].map(function (item) {
            return (
              <option disabled={item.isDailyMaintenance} key={item._id} value={item._id}>{item.label}</option>
            );
          })
        }
      </optgroup>
    );
  });

  return ret;
}
