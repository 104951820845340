import React, { useContext, useEffect } from 'react';
import CompanySettingContext from 'features/context/companySettingContext';
import { Helmet } from 'react-helmet';

export default function PWAHelmet() {
  const {
    pageConfig = {},
    ready: pageConfigReady,
  } = useContext(CompanySettingContext);

  const {
    name,
    themeColor,
  } = pageConfig?.pwaManifest || {};

  useEffect(() => {
    if (!pageConfigReady) return;

    const { headScriptInjection } = pageConfig;

    if (!headScriptInjection) return;

    const head = document.head;
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = headScriptInjection;

    const dynamicElements = [];

    for (const child of tempDiv.children) {
      const element = document.createElement(child.tagName);
      element.innerHTML = child.innerHTML;
      head.appendChild(element);
      dynamicElements.push(element);
    }

    return () => {
      // Clean up dynamic elements
      dynamicElements.forEach((el) => {
        if (head.contains(el)) head.removeChild(el);
      });
    };
  }, [pageConfigReady, pageConfig]);

  if (!pageConfigReady) return null;

  return (
    <Helmet>
      <title>{name}</title>
      <meta name="description" content={name} />
      <meta name="theme-color" content={themeColor} />
    </Helmet>
  );
}