import React, { useMemo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  LinearProgress,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import useSWR from 'swr';
import feathers from 'services/feathers';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import Decimal from 'decimal.js';

export default function TurnoverInfo() {
  const { t } = useTranslation();
  const { data: turnoverInfo, isLoading, error } = useSWR('turnovers', () => feathers.service('turnover-info').get(nanoid()), { refreshInterval: 30000 });
  const [ openSummaryDialog, setOpenSummaryDialog ] = useState(false);

  const turnoverLabel = useMemo(
    () => {
      // show Turnover progress: N/N (N%)
      const { currentTurnover = 0, totalTurnoverRequirement = 0, completedTurnoverPercentage = 0 } = turnoverInfo || {};
      return `${currentTurnover}/${totalTurnoverRequirement} (${completedTurnoverPercentage}%)`;
    }, [turnoverInfo]
  );

  const sortedSummary = useMemo(
    () => {
      if (!turnoverInfo?.summary) return [];

      const mappedSummary = turnoverInfo.summary.map((item) => {
        const { gameType, gameName } = item;

        const formattedGameName = gameType === gameName ? gameType : `${gameName} (${gameType})`;

        return {
          ...item,
          formattedGameName,
        };
      }).sort((a, b) => a.formattedGameName.localeCompare(b.formattedGameName));

      return mappedSummary;
    }, [turnoverInfo]
  );

  const renderRow = useCallback(
    (props) => {
      const { index, style } = props;
      const item = sortedSummary[index];

      const { formattedGameName, gameType, gameName, totalBet, count } = item;
      const formattedTotalBet = new Decimal(totalBet).toFixed(2);

      return (
        <ListItem key={formattedGameName} style={style}>
          <ListItemText
          primary={
            <Typography variant='body1' color='textPrimary'>{gameName}</Typography>
          }
          secondary={
            <Box display='flex' flexDirection='column'>
              {
                gameType !== gameName &&
                  <Typography variant='caption' color='textSecondary'>{gameType}</Typography>
              }
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2' color='textPrimary'>x{count}</Typography>
                <Typography variant='subtitle2' color='textPrimary'>${formattedTotalBet}</Typography>
              </Box>
            </Box>
          } />
        </ListItem>
      );
    }, [sortedSummary]
  );

  if (error) return (
    <Box>
      <Typography variant="h6" color="error">Error loading turnover info</Typography>
    </Box>
  );

  return (
    <Card elevation={0}>
      <CardHeader title={t('Turnover Info')} />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '100%', my: 2 }}>
        {
          isLoading ? (
            <LinearProgress />
          ) : (
            <LinearProgress variant='determinate' value={turnoverInfo?.completedTurnoverPercentage || 0} />
          )
        }
        </Box>
        <Typography variant='body2' color='textSecondary'>
          {turnoverLabel}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => setOpenSummaryDialog(true)}>
          {t('View Details')}
        </Button>
        <Dialog open={openSummaryDialog} onClose={() => setOpenSummaryDialog(false)}>
          <DialogTitle>{t('Turnover Summary')}</DialogTitle>
          <DialogContent>
            {
              !sortedSummary.length ? (
                <Paper sx={{ p: 2 }}>
                  <Typography variant='body2' color='textSecondary'>{t('No data')}</Typography>
                </Paper>
              ) : null
            }
            <Box sx={{ width: '100%', height: 400, maxWidth: 240, bgcolor: 'background.paper' }}>
              <FixedSizeList
                height={400}
                width={240}
                itemSize={80}
                itemCount={turnoverInfo?.summary?.length || 0}
              >
                {renderRow}
              </FixedSizeList>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSummaryDialog(false)}>{t('Close')}</Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
}