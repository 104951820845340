import { get } from 'lodash';

export function generateBotUrl(bot, type, lang = 'en', referrerId = '') {
  if (!bot) return null;

  const userId = getUserIdByBotType(bot, type);
  if (!userId) return null;

  if (type === 'whatsapp') {
    const rawText = `/join/${lang} ${referrerId}`;
    const text = encodeURI(rawText.trim());
    return `https://wa.me/${userId}/?text=${text}`;
  } else if (type === 'telegram') {
    const rawText = `-lang-${lang}-${referrerId}`;
    const text = encodeURI(rawText.trim());
    return `https://t.me/${userId}?start=${text}`;
  } else if (type === 'line') {
    const rawText = `/join/${lang} ${referrerId}`;
    const text = encodeURIComponent(rawText.trim());
    const encodedUserId = encodeURIComponent(userId);
    return `https://line.me/R/oaMessage/${encodedUserId}/?${text}`;
  }

  return null;
}

function getUserIdByBotType(bot, type) {
  if (!bot) return null;

  if (type === 'whatsapp') return get(bot, 'phoneNumber');
  else if (type === 'telegram') return get(bot, 'username');
  else if (type === 'line') return get(bot, 'botBasicId');

  return null;
}

export function generateCsUrl(cs, type) {
  if (!cs) return null;

  const userId = get(cs, type);
  if (!userId) return null;

  if (type === 'whatsapp') return `https://wa.me/${userId}`;
  else if (type === 'telegram') return `https://t.me/${userId}`;
  else if (type === 'line') {
    const encodedUserId = encodeURIComponent(userId);
    return `https://line.me/R/ti/p/${encodedUserId}`;
  }

  return null;
}

export function generateSmsUrl(cs) {
  if (!cs) return null;

  const phoneNumber = get(cs, 'phoneNumber');
  if (!phoneNumber) return null;

  return `sms://+${phoneNumber}?body=Hi`
}

export function generateCallUrl(cs) {
  if (!cs) return null;

  const phoneNumber = get(cs, 'phoneNumber');
  if (!phoneNumber) return null;

  return `tel://+${phoneNumber}`
}
