import React, { useContext, useState, useMemo, useEffect } from 'react';
import { _CASH_SYMBOL, _POINT_SYMBOL } from 'utils/symbol';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CommonContext from 'features/context/commonContext';
import { useAuth } from 'hooks/useAuth';
import { get } from 'lodash';
import Decimal from 'decimal.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/InfoTwoTone';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import CountUp from 'react-countup';
import Grow from '@mui/material/Grow';
import ReferralChart from './Chart';
import { QRCodeCanvas } from 'qrcode.react';
import {
  TextField,
  InputAdornment,
} from '@mui/material';

export default function Referral(props) {
  const { user } = useAuth();
  const [ showInfo, setShowInfo ] = useState(false);
  const { userData } = useContext(CommonContext);

  const referrerUsername = useMemo(() => (get(userData, 'referrerUsername', '')), [userData]);
  const refereeCount = useMemo(() => (get(userData, 'refereeCount', 0)), [userData]);

  const referralLink = useMemo(() => {
    const currentUrl = window.location.origin;
    const refId = user._id;
    return `${currentUrl}/login?refId=${refId}`;
  }, [user])

  const decPointRewardAmount = useMemo(
    () => (
      new Decimal(get(userData, 'referralReward.point.amount.$numberDecimal', '0'))
    ), [userData]
  );

  const decCashRewardAmount = useMemo(
    () => (
      new Decimal(get(userData, 'referralReward.cash.amount.$numberDecimal', '0'))
    ), [userData]
  );

  const referralRewardType = useMemo(() => (get(user, 'referralReward.type', 'point')), [user]);
  const isCashReward = useMemo(() => (referralRewardType === 'both' || referralRewardType === 'cash'), [referralRewardType]);
  const isPointReward = useMemo(() => (referralRewardType === 'both' || referralRewardType === 'point'), [referralRewardType]);

  const decPointMultiplier = useMemo(
    () => (
      new Decimal(get(user, 'referralReward.pointMultiplier.$numberDecimal', '0'))
    ), [user]
  );

  const decCashMultiplier = useMemo(
    () => (
      new Decimal(get(user, 'referralReward.cashMultiplier.$numberDecimal', '0'))
    ), [user]
  );

  const { t } = useTranslation();
  const [ cuRefCount, setCuRefCount ] = useState({ start: 0, end: 0 });
  const [ cuPointAmount, setCuPointAmount ] = useState({ start: 0, end: 0 });
  const [ cuCashAmount, setCuCashAmount ] = useState({ start: 0, end: 0 });

  useEffect(() => {
    setCuRefCount(prev => {
      if (prev?.end === refereeCount) return prev;

      return {
        start: prev?.end,
        end: refereeCount
      }
    });
  }, [refereeCount]);

  useEffect(() => {
    setCuPointAmount(prev => {
      if (prev?.end === decPointRewardAmount.toNumber()) return prev;

      return {
        start: prev?.end,
        end: decPointRewardAmount.toNumber()
      }
    });
  }, [decPointRewardAmount]);

  useEffect(() => {
    setCuCashAmount(prev => {
      if (prev?.end === decCashRewardAmount.toNumber()) return prev;

      return {
        start: prev?.end,
        end: decCashRewardAmount.toNumber()
      }
    });
  }, [decCashRewardAmount]);

  function onInfoClicked(event) {
    event.preventDefault();
    setShowInfo(prev => !prev);
  }

  function showReferralInfo() {
    return (
      <Card elevation={4}>
        <CardHeader title={t('Referral Program')} subheader={t('Start inviting your friends and earn rewards')} />
        <Divider />
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <QRCodeCanvas
              size={180}
              value={referralLink}
              level={'Q'}
            />
          </Box>
          <TextField
            fullWidth
            variant='outlined'
            label={t('Referral Link')}
            value={referralLink}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <CopyToClipboard text={referralLink} copiedText={t('Referral link copied')}>
                    <IconButton>
                      <CopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </InputAdornment>
              )
            }}
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {
            showReferralInfo()
          }
        </Grid>
        <Grid item xs={12}>
          <Card elevation={2}>
            <CardHeader
              title={t('Referral Stats')}
              subheader={!!referrerUsername ? t('My Referrer', { username: referrerUsername }) : ''}
              action={
                <IconButton color='inherit' onClick={onInfoClicked}>
                  {
                    showInfo ? <CloseIcon /> : <InfoIcon />
                  }
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                {
                  !!showInfo && (
                    <Grow in={!!showInfo}>
                      <Grid item xs={12}>
                        <Alert severity="info">
                          {`${t('Refer benefit')} ${_POINT_SYMBOL} 🟰 ♾️`}
                        </Alert>
                      </Grid>
                    </Grow>
                  )
                }
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: 2 }}>
                      <Typography variant='subtitle2' gutterBottom>
                        {
                          t('Referrals')
                        }
                      </Typography>
                      <CountUp
                        start={cuRefCount.start}
                        end={cuRefCount.end}
                        duration={0.88}
                        separator=" "
                        decimals={0}
                        decimal="."
                        prefix='👭 '
                        suffix=''
                      >
                        {({ countUpRef, start }) => (
                          <Typography sx={{ textAlign: 'right' }} variant="h6" ref={countUpRef} />
                        )}
                      </CountUp>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: 2 }}>
                    <Typography variant='subtitle2' gutterBottom>
                      {
                        `${t('Total Point Reward')}`
                      }
                    </Typography>
                    <CountUp
                      start={cuPointAmount.start}
                      end={cuPointAmount.end}
                      duration={0.88}
                      separator=" "
                      decimals={2}
                      decimal="."
                      prefix={`${_POINT_SYMBOL} `}
                      suffix=''
                    >
                      {({ countUpRef, start }) => (
                        <Typography gutterBottom sx={{ textAlign: 'right' }} variant="h6" ref={countUpRef} />
                      )}
                    </CountUp>
                    {
                      isPointReward ?
                        <Typography variant='subtitle2' sx={{ color: 'info.light', fontStyle: 'oblique' }}>
                          {t('On each deposit', { percentage: decPointMultiplier.times(100).toFixed(2) })}
                        </Typography> :
                        <Typography variant='subtitle2' sx={{ color: 'warning.light', fontStyle: 'oblique' }}>
                          {t('Apply via customer service')}
                        </Typography>
                    }
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: 2 }}>
                    <Typography variant='subtitle2' gutterBottom>
                      {
                        `${t('Total Cash Reward')}`
                      }
                    </Typography>
                    <CountUp
                      start={cuCashAmount.start}
                      end={cuCashAmount.end}
                      duration={0.88}
                      separator=" "
                      decimals={2}
                      decimal="."
                      prefix={`${_CASH_SYMBOL} `}
                      suffix=''
                    >
                      {({ countUpRef, start }) => (
                        <Typography gutterBottom sx={{ textAlign: 'right' }} variant="h6" ref={countUpRef} />
                      )}
                    </CountUp>
                    {
                      isCashReward ?
                        <Typography variant='subtitle2' sx={{ color: 'info.light', fontStyle: 'oblique' }}>
                          {t('On each deposit', { percentage: decCashMultiplier.times(100).toFixed(2) })}
                        </Typography> :
                        <Typography variant='subtitle2' sx={{ color: 'warning.light', fontStyle: 'oblique' }}>
                          {t('Apply via customer service')}
                        </Typography>
                    }
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4}>
            <ReferralChart data={userData} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
